import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from '../services/person.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public trainerInfo: any;
  public trainers: any[];
  public showInfo: any;
  public showId: any;
  public personId: any;
  public personInfo: any;
  public entries: any[];
  constructor(
    private _route: ActivatedRoute,
    private personService: PersonService
  ) {
    this.personId = this._route.snapshot.paramMap.get('personID').replace('us_','');
    this.showId = this._route.snapshot.paramMap.get('showID').replace('us_','');
    
  }

  ngOnInit(): void {
    //console.log(this.personId);
    //console.log(this.showId);
    this.getPersonData(this.personId, this.showId);
  }

  public getPersonData(personId, showId) {
    this.personService.getPerson(personId, showId).subscribe(
      response => {
      this.personInfo = response.data.personInfo;
      this.entries = response.data.results;
    });
  }
}
